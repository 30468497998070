import { gql } from '@apollo/client';

const mailingAddressFragment = gql`
  fragment MailingAddress on MailingAddress {
    address1
    address2
    city
    company
    country
    firstName
    id
    lastName
    phone
    province
    zip
  }
`;

const customerFragment = gql`
  ${mailingAddressFragment}
  fragment Customer on Customer {
    acceptsMarketing
    defaultAddress {
      ...MailingAddress
    }
    displayName
    email
    firstName
    id
    lastIncompleteCheckout {
      id
      webUrl
    }
    lastName
    phone
    tags
  }
`;

const orderFragment = gql`
  ${mailingAddressFragment}
  fragment Order on Order {
    cancelReason
    canceledAt
    currencyCode
    currentSubtotalPrice {
      amount
      currencyCode
    }
    currentTotalDuties {
      amount
      currencyCode
    }
    currentTotalTax {
      amount
      currencyCode
    }
    customerLocale
    customerUrl
    edited
    email
    financialStatus
    fulfillmentStatus
    id
    lineItems(first: 100) {
      edges {
        node {
          currentQuantity
          discountAllocations {
            allocatedAmount {
              amount
            }
          }
          discountedTotalPrice {
            amount
          }
          originalTotalPrice {
            amount
          }
          quantity
          variant {
            image {
              url
            }
            product {
              handle
              vendor
            }
            sku
          }
          title
        }
      }
    }
    name
    orderNumber
    originalTotalDuties {
      amount
      currencyCode
    }
    originalTotalPrice {
      amount
      currencyCode
    }
    phone
    processedAt
    shippingAddress {
      ...MailingAddress
    }
    shippingDiscountAllocations {
      allocatedAmount {
        amount
        currencyCode
      }
      discountApplication {
        allocationMethod
        targetSelection
        targetType
        value
      }
    }
    statusUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    successfulFulfillments {
      trackingCompany
      trackingInfo {
        number
        url
      }
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    totalRefundedV2 {
      amount
      currencyCode
    }
    totalShippingPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
  }
`;

export const customer = (accessToken) => {
  return {
    query: gql`
      ${customerFragment}
      query customer($input: String!) {
        customer(customerAccessToken: $input) {
          ...Customer
        }
      }
    `,
    variables: {
      input: accessToken,
    },
  };
};

export const customerAccessTokenCreate = ({ email, password }) => {
  return {
    mutation: gql`
      mutation customerAccessTokenCreate(
        $input: CustomerAccessTokenCreateInput!
      ) {
        customerAccessTokenCreate(input: $input) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      input: {
        email: email,
        password: password,
      },
    },
  };
};

export const customerAccessTokenDelete = (accessToken) => {
  return {
    mutation: gql`
      mutation customerAccessTokenDelete($input: String!) {
        customerAccessTokenDelete(customerAccessToken: $input) {
          deletedAccessToken
          deletedCustomerAccessTokenId
          userErrors {
            field
            message
          }
        }
      }
    `,
    variables: {
      input: accessToken,
    },
  };
};

export const customerActivateByUrl = ({ activationUrl, password }) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
        customerActivateByUrl(
          activationUrl: $activationUrl
          password: $password
        ) {
          customer {
            ...Customer
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      activationUrl: activationUrl,
      password: password,
    },
  };
};

export const customerAddressCreate = (
  {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    lastName,
    phone,
    province,
    zip,
  },
  accessToken
) => {
  return {
    mutation: gql`
      ${mailingAddressFragment}
      mutation customerAddressCreate(
        $address: MailingAddressInput!
        $customerAccessToken: String!
      ) {
        customerAddressCreate(
          address: $address
          customerAccessToken: $customerAccessToken
        ) {
          customerAddress {
            ...MailingAddress
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      address: {
        address1: address1,
        address2: address2,
        city: city,
        company: company,
        country: country,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        province: province,
        zip: zip,
      },
      customerAccessToken: accessToken,
    },
  };
};

export const customerAddressDelete = ({ id }, accessToken) => {
  return {
    mutation: gql`
      mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
        customerAddressDelete(
          customerAccessToken: $customerAccessToken
          id: $id
        ) {
          customerUserErrors {
            code
            field
            message
          }
          deletedCustomerAddressId
        }
      }
    `,
    variables: {
      customerAccessToken: accessToken,
      id: id,
    },
  };
};

export const customerAddressUpdate = (
  {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    id,
    lastName,
    phone,
    province,
    zip,
  },
  accessToken
) => {
  return {
    mutation: gql`
      ${mailingAddressFragment}
      mutation customerAddressUpdate(
        $address: MailingAddressInput!
        $customerAccessToken: String!
        $id: ID!
      ) {
        customerAddressUpdate(
          address: $address
          customerAccessToken: $customerAccessToken
          id: $id
        ) {
          customerAddress {
            ...MailingAddress
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      address: {
        address1: address1,
        address2: address2,
        city: city,
        company: company,
        country: country,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        province: province,
        zip: zip,
      },
      customerAccessToken: accessToken,
      id: id,
    },
  };
};

export const checkoutCustomerAssociateV2 = (checkoutId, accessToken) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation checkoutCustomerAssociateV2(
        $checkoutId: ID!
        $customerAccessToken: String!
      ) {
        checkoutCustomerAssociateV2(
          checkoutId: $checkoutId
          customerAccessToken: $customerAccessToken
        ) {
          customer {
            ...Customer
          }
          checkoutUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      checkoutId: checkoutId,
      customerAccessToken: accessToken,
    },
  };
};

export const checkoutCustomerDisassociateV2 = (checkoutId) => {
  return {
    mutation: gql`
      mutation checkoutCustomerDisassociateV2($checkoutId: ID!) {
        checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
          checkout {
            id
          }
          checkoutUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      checkoutId: checkoutId,
    },
  };
};

export const customerCreate = ({ acceptsMarketing, email, password }) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation customerCreate($input: CustomerCreateInput!) {
        customerCreate(input: $input) {
          customer {
            ...Customer
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      input: {
        acceptsMarketing: acceptsMarketing,
        email: email,
        password: password,
      },
    },
  };
};

export const customerDefaultAddressUpdate = ({ id }, accessToken) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation customerDefaultAddressUpdate(
        $addressId: ID!
        $customerAccessToken: String!
      ) {
        customerDefaultAddressUpdate(
          addressId: $addressId
          customerAccessToken: $customerAccessToken
        ) {
          customer {
            ...Customer
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      addressId: id,
      customerAccessToken: accessToken,
    },
  };
};

export const customerRecover = (email) => {
  return {
    mutation: gql`
      mutation customerRecover($email: String!) {
        customerRecover(email: $email) {
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      email: email,
    },
  };
};

export const customerResetByUrl = ({ password, resetUrl }) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
        customerResetByUrl(password: $password, resetUrl: $resetUrl) {
          customer {
            ...Customer
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      password: password,
      resetUrl: resetUrl,
    },
  };
};

export const customerUpdate = (
  { acceptsMarketing, email, firstName, lastName, phone },
  accessToken
) => {
  return {
    mutation: gql`
      ${customerFragment}
      mutation customerUpdate(
        $customer: CustomerUpdateInput!
        $customerAccessToken: String!
      ) {
        customerUpdate(
          customer: $customer
          customerAccessToken: $customerAccessToken
        ) {
          customer {
            ...Customer
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `,
    variables: {
      customer: {
        acceptsMarketing: acceptsMarketing,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      },
      customerAccessToken: accessToken,
    },
  };
};

export const addresses = (accessToken) => {
  return {
    query: gql`
      ${mailingAddressFragment}
      query customer($input: String!) {
        customer(customerAccessToken: $input) {
          addresses(first: 250) {
            edges {
              node {
                ...MailingAddress
              }
            }
          }
        }
      }
    `,
    variables: {
      input: accessToken,
    },
  };
};

export const orders = (accessToken) => {
  return {
    query: gql`
      ${orderFragment}
      query customer($input: String!) {
        customer(customerAccessToken: $input) {
          orders(first: 50, reverse: true) {
            edges {
              node {
                ...Order
              }
            }
          }
        }
      }
    `,
    variables: {
      input: accessToken,
    },
  };
};
